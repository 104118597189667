#overlay {
    position: fixed;
    z-index: 5;
    width: 70vw;
    height: 85vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgb(255, 255, 255);
    display: block;
    border-radius: 1rem;
    padding: 48px;

} 

#overlay_border {
    /* https://cssgradient.io/ */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: rgb(66, 61, 152);
    background: linear-gradient(90deg, rgba(66,61,152,1) 0%, rgba(121,53,131,1) 25%, rgba(60,60,150,1) 57%, rgba(7,153,182,1) 100%);
    width: 72vw;
    height: 89vh;
    z-index: 4;
    display: block;
    border-radius: 1rem;
}

/* ==================== OVERLAY DIM ===================== */

#overlay_background {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    display: block;
    z-index: 3;
}

/* =================== OVERLAY CONTENT ====================== */

.overlay_upper {
    gap: 48px;
    height: 95%;
} .overlay_upper > img {
    border-radius: 1rem;
    height: 256px;
    border: 3px black solid;
    
    /* For trasparent images */
    background-color: rgb(255, 255, 255);
}

.overlay_left {
    overflow-y: auto;
    height: 100%;
    padding: 0 12px 0 0;
    overscroll-behavior: "contain";
}