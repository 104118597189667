/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #e7e7e7 #eaf0f1;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    scrollbar-color: grey transparent;
}

*::-webkit-scrollbar-track {
    background-color: transparent;
}

*::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #d4cccc;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #b5b5b5;
}

*::-webkit-scrollbar-thumb:active {
    background-color: #888888;
}