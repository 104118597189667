@keyframes fadein {
    from { 
        opacity: 0; 
    }
    to { 
        opacity: 1; 
    }
}

.homepage, .personal_projects, .footer {
    opacity: 0;
}

.homepage, .personal_projects, .footer {
    animation-name: fadein;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

/* Animate projects one at a time */
.project_card {
    opacity: 0;
    animation-name: fadein;
    animation-duration: 0.75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
} .project_card:nth-child(1) {
    animation-name: fadein;
} .project_card:nth-child(2) {
    animation-delay: 0.15s;
} .project_card:nth-child(3) {
    animation-delay: 0.3s;
} .project_card:nth-child(4) {
    animation-delay: 0.45s;
} .project_card:nth-child(5) {
    animation-delay: 0.6s;
} .project_card:nth-child(6) {
    animation-delay: 0.75s;
}

/* ============================================================================= */

/* ----------------------------------------------
 * Generated by Animista on 2023-9-2 11:57:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/**
 * ----------------------------------------
 * animation scale-down-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
}

@keyframes scale-down-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
}

#overlay, #overlay_border {
    animation-name: scale-up-center;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.scale_down_center {
    animation-name: scale-down-center;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}