@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap");

/* Hide the horizontal scrollbar */
.hide_horizontal_scrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
} .hide_horizontal_scrollbar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

#return_fake_msg {
    background-color: rgb(0, 81, 255);
    padding: 1em 1.5em;
    border-radius: 1em;
    text-align: center;
} #return_fake_msg:hover {
    background-color: rgb(43, 191, 211);
} #return_real_msg:hover {
    text-decoration: underline;
}