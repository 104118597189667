.outline {
    border: red 1px solid;
}

.hover_increase {
    transition: transform 0.25s;
} .hover_increase:hover {
    transform: scale(1.15);
}

.hover_increase_small {
    transition: transform 0.25s;
} .hover_increase_small:hover {
    transform: scale(1.075);
}

.hover_increase_smaller {
    transition: transform 0.25s;
} .hover_increase_smaller:hover {
    transform: scale(1.03);
}

.cursor_pointer:hover {
    cursor: pointer;
}