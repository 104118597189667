/* https://www.gradient-animator.com/ */

body {
    background: linear-gradient(102deg, #d02887, #ce3434, #d28923);
    background-size: 600% 600%;

    -webkit-animation: Animation 20s ease infinite;
    -moz-animation: Animation 20s ease infinite;
    -o-animation: Animation 20s ease infinite;
    animation: Animation 20s ease infinite;
}

@-webkit-keyframes Animation {
    0%{background-position:87% 0%}
    50%{background-position:14% 100%}
    100%{background-position:87% 0%}
}
@-moz-keyframes Animation {
    0%{background-position:87% 0%}
    50%{background-position:14% 100%}
    100%{background-position:87% 0%}
}
@-o-keyframes Animation {
    0%{background-position:87% 0%}
    50%{background-position:14% 100%}
    100%{background-position:87% 0%}
}
@keyframes Animation {
    0%{background-position:87% 0%}
    50%{background-position:14% 100%}
    100%{background-position:87% 0%}
}